import React from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { Translate } from 'react-localize-redux';

interface FormInputProps {
  name: string;
  label?: string;
  showLabel?: boolean;
  type?: 'text' | 'password' | 'date' | 'tel' | 'select';
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  disabled?: boolean;
  className?: string;
  options?: { value: string; label: string }[];
  validation?: object;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  testId?: string;
}

export const FormInput = ({
  name,
  label,
  showLabel = true,
  type = 'text',
  register,
  errors,
  disabled = false,
  className = '',
  options = [],
  validation = {},
  onChange,
  testId,
}: FormInputProps) => {
  const getNestedError = (path: string) => {
    return path.split('.').reduce((acc: any, curr: string) => {
      return acc?.[curr];
    }, errors);
  };

  const error = getNestedError(name);

  const baseClassName = `
    gr-block gr-py-2 gr-text-md gr-rounded-md gr-shadow-sm 
    focus:gr-ring-2 focus:gr-ring-gray-900 focus:gr-border-gray-900
    ${error ? 'gr-border-pink-700 gr-bg-pink-50' : 'gr-border-gray-300 gr-bg-white'}
    ${className}
  `;

  const inputTestId = testId || `input-${name}`;

  const renderInput = () => {
    if (type === 'select') {
      return (
        <select
          {...register(name, validation)}
          className={`${baseClassName} gr-pl-6`}
          disabled={disabled}
          onChange={onChange}
          data-testid={inputTestId}
        >
          <option value="" disabled data-testid={`${inputTestId}-placeholder`}>
            Select <Translate id={`identity_document.${label}`} />
          </option>
          {options.map(({ value, label }) => (
            <option
              key={`${name}${value}${label}`}
              value={value}
              data-testid={`${inputTestId}-option-${value}`}
            >
              {label}
            </option>
          ))}
        </select>
      );
    } else {
      return (
        <input
          type={type}
          {...register(name, validation)}
          className={`${baseClassName} ${type === 'text' ? 'gr-w-full' : ''}`}
          disabled={disabled}
          data-testid={inputTestId}
        />
      );
    }
  };

  return (
    <div className="gr-mb-4" data-testid={`form-group-${name}`}>
      {label && showLabel ? (
        <label
          className="gr-mb-1 gr-block gr-text-sm gr-font-medium gr-text-gray-700"
          data-testid={`label-${name}`}
        >
          <Translate id={`identity_document.${label}`} />
        </label>
      ) : null}
      {renderInput()}
      {error && (
        <div
          className="gr-text-red-500 gr-text-sm gr-mt-1"
          data-testid={`error-${inputTestId}`}
        >
          {error.message}
        </div>
      )}
    </div>
  );
};
