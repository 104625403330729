import React, { useState } from 'react';
import { FormInput } from '../shared/FormInput';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { Translate } from 'react-localize-redux';
import {
  countries,
  TCountryCode,
  getEmojiFlag,
  getCountryData,
} from 'countries-list';

interface PhoneInputProps {
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  disabled?: boolean;
  testId?: string;
}

export const PhoneInput = ({
  register,
  errors,
  disabled,
  testId,
}: PhoneInputProps) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState('');

  return (
    <div className="gr-mb-4">
      <label className="gr-mb-2 gr-block gr-text-sm gr-font-medium gr-text-gray-700">
        <Translate id="identity_document.contact_phone" />
      </label>
      <div className="gr-flex gr-flex-col md:gr-flex-row gr-gap-2">
        <FormInput
          name={`phoneNumberCountryCode`}
          label="contact_phone_country_code"
          type="select"
          register={register}
          showLabel={false}
          errors={errors}
          disabled={disabled}
          className="gr-w-full md:gr-w-40"
          options={Object.entries(countries).map(([code, country]) => ({
            value: String(getCountryData(code as TCountryCode).phone[0]),
            label: `${getEmojiFlag(code as TCountryCode)} ${country.name}`,
          }))}
          onChange={(e) => setSelectedCountryCode(e.target.value)}
          validation={{ required: 'Country dial code required' }}
          testId={`${testId}-countryCode`}
        />
        <div className="md:gr-mb-4">
          <input
            value={selectedCountryCode ? `+${selectedCountryCode}` : ''}
            disabled
            className="gr-text-center gr-w-full md:gr-w-10 gr-pl-1 gr-py-2 gr-bg-gray-100 gr-border gr-border-gray-300 gr-rounded-md"
          />
        </div>
        <FormInput
          name="phoneNumber"
          type="tel"
          register={register}
          errors={errors}
          disabled={disabled}
          className="gr-w-full md:gr-flex-1"
          validation={{
            required: 'Phone number required',
            pattern: {
              value: /^[0-9]*$/,
              message: 'Phone number must contain only numbers',
            },
          }}
          testId={`${testId}-phoneNumber`}
        />
      </div>
    </div>
  );
};
