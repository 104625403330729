import React, { Component } from 'react';
import { connect } from 'react-redux';

import Compartments from './Compartments';
import Categories from './Categories';
import {
  updateSeatDetailsPosition,
  seatMapScrolled,
} from '../../redux/actions';
import { rtlLangs } from '../../translations/rtl-langs';

const MOBILE_WIDTH_THRESHOLD = 650;

const preventDefault = (e) => e.preventDefault();
// When rendering our container
window.addEventListener('touchmove', preventDefault, {
  passive: false,
});
// Remember to clean up when removing it
window.removeEventListener('touchmove', preventDefault);

class SeatMapContainer extends Component {
  myRef = React.createRef();

  componentDidUpdate(prevProps) {
    const prevSeatmap = prevProps.seatmap?.segment_id;
    const currSeatmap = this.props.seatmap?.segment_id;
    const currentSeats = this.props.currentSeats;
    const modalShown = this.props.modalShown;
    if (
      prevSeatmap !== currSeatmap ||
      (prevProps.modalShown !== modalShown && modalShown)
    ) {
      this.myRef.current.scrollTo(0, 0);
      if (currentSeats.length) {
        const currentSeatmapSeats = currentSeats.filter(
          (cs) => cs.segment_id === currSeatmap
        );
        if (currentSeatmapSeats.length) {
          const currentSeatElement = document.querySelector(
            `.gordian-seat[aria-label*=' ${
              currentSeatmapSeats[currentSeatmapSeats.length - 1].seat
            } ']`
          );
          if (currentSeatElement) {
            const rect = currentSeatElement.getBoundingClientRect();

            const isVisible =
              rect.top >= 0 &&
              rect.left >= 0 &&
              rect.bottom <=
                (window.innerHeight || document.documentElement.clientHeight) &&
              rect.right <=
                (window.innerWidth || document.documentElement.clientWidth);
            if (!isVisible) {
              currentSeatElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
            }
          }
        }
      }
    }
  }

  render() {
    const { brand = undefined, locale, theme } = this.props;

    let bgColor = undefined;
    let paddingClassname = undefined;
    switch (brand) {
      case 'uber':
        bgColor = '#fff';
        break;
      default:
        bgColor = theme?.seatmap?.background ?? '#EEF4F9';
        paddingClassname = 'gr-pt-5';
    }

    return (
      <div
        ref={this.myRef}
        onScroll={(event) => {
          var element = event.target;
          if (
            element.scrollHeight - element.scrollTop ===
            element.clientHeight
          ) {
            this.props.seatMapScrolled(true);
          } else {
            this.props.seatMapScrolled(false);
          }
        }}
        id="seat-map"
        className={`seat-map ${paddingClassname} gr-flex gr-flex-row gr-border-l gr-border-r  gr-relative gr-h-auto ${
          this.props.modal ? this.props.heightClass : 'gr-h-embedded'
        } gr-w-full gr-scrolling-touch ${
          this.props.basketChanging
            ? 'gr-overflow-y-hidden'
            : 'gr-overflow-y-auto'
        } gr-overflow-x-hidden`}
        dir={rtlLangs.includes(locale) ? 'rtl' : 'ltr'}
        style={{
          backgroundColor: bgColor,
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export class SeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollTop: 0,
      width: typeof window !== 'undefined' ? window.innerWidth : 1200,
      height: typeof window !== 'undefined' ? window.innerHeight : 800,
    };

    this.state.is_mobile = this.state.width <= MOBILE_WIDTH_THRESHOLD;
  }

  componentDidMount() {
    document.getElementById('seat-map').addEventListener(
      'touchmove',
      (event) => {
        event.stopPropagation();
      },
      false
    );
  }

  render() {
    const { seatmap = {}, modal, seatMapScrolled, variants } = this.props;
    const { test_seatmap_no_close = false } = variants;
    const { is_mobile } = this.state;
    const { decks = [{ compartments: [] }] } = seatmap;
    const compartments = decks.reduce((prev, { compartments, wings = {} }) => {
      const compartmentsWithWings = compartments.map((compartments) => ({
        ...compartments,
        wings,
      }));
      return [...prev, ...compartmentsWithWings];
    }, []);

    let brand = undefined;
    if (variants.test_uber_theme === true) brand = 'uber';

    const compartmentsArray = (
      <Compartments
        key="compartments"
        compartments={compartments}
      ></Compartments>
    );
    let heightClass = 'gr-h-full';
    if (!test_seatmap_no_close) {
      heightClass = 'gr-h-modal';
    } else {
      heightClass = 'gr-h-modal-no-exit';
    }

    return (
      <SeatMapContainer
        modal={modal}
        is_mobile={is_mobile}
        seatMapScrolled={seatMapScrolled}
        heightClass={heightClass}
        basketChanging={this.props.basketChanging}
        brand={brand}
        {...this.props}
      >
        <Categories />
        {compartmentsArray}
      </SeatMapContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    seatmap: state.itinerary.seatmapsBySegment[state.itinerary.currentSeatmap],
    variants: state.session.variants,
    basketChanging: state.session.basketChanging,
    relativeWidthRatio: state.session.relativeWidthRatio,
    locale: state.session.locale,
    currentSeats: state.basket.seats,
    modalShown: state.session.modalShown,
    theme: state.session.theme,
  };
};

const mapDispatchToProps = { updateSeatDetailsPosition, seatMapScrolled };

export default connect(mapStateToProps, mapDispatchToProps)(SeatMap);
