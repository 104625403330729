import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { SubmitHandler, useForm, UseFormRegister } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form';
import { countries, getEmojiFlag, TCountryCode } from 'countries-list';
import { ClipLoader } from 'react-spinners';

import DocumentTypeSelect from './IdentityDocumentTypeSelect';
import SelectArrow from '../shared/SelectArrow';
import { DocumentType, IdentityDocument } from './identityDocumentTypes';
import { FormInput } from '../shared/FormInput';
import { setACIData } from '../../redux/actions';
import {
  convertToApiFormat,
  isFutureDate,
} from '../../utils/identity_document/identityDocumentUtils';
import { PhoneInput } from '../identity_document/PhoneInput';
import WarningIcon from './WarningIcon';

interface ActionStatus {
  message: string;
  isError: boolean;
}

interface WrapFormProps {
  values: IdentityDocument;
  onSubmit: SubmitHandler<IdentityDocument>;
  onDelete: () => Promise<void>;
  isSelected: boolean;
  apiCallLoading: boolean;
  actionStatus: ActionStatus | null;
  children: (
    register: UseFormRegister<any>,
    errors: FieldErrors<any>,
    isDisabled: boolean
  ) => JSX.Element;
}

function WrapForm({
  values,
  onSubmit,
  onDelete,
  isSelected,
  apiCallLoading,
  actionStatus,
  children,
}: WrapFormProps): JSX.Element {
  // @ts-ignore: Redux state is not typed
  const isMobile = useSelector((state) => state.session.mobile);
  // @ts-ignore: Redux state is not typed
  const theme = useSelector((state) => state.session.theme);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    values,
  });

  const shouldHideDeleteButton = (
    isSelected: boolean,
    values: IdentityDocument
  ): boolean => {
    if (!isSelected) return true;
    return values?.document_id ? false : true;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {children(register, errors, !shouldHideDeleteButton(isSelected, values))}
      <button
        type="button"
        hidden={shouldHideDeleteButton(isSelected, values)}
        onClick={onDelete}
        className={`gr-cursor-pointer gr-font-medium gr-text-center gr-h-12 ${
          isMobile ? 'gr-w-48' : 'gr-min-w-200'
        } gr-text-base gr-border-0 disabled:gr-opacity-50 disabled:gr-cursor-not-allowed gr-mr-2`}
        style={{
          background: theme?.bags?.primaryButtonColor ?? '#4CA7DE',
          color: theme?.bags?.primaryButtonText ?? '#FFFFFF',
          borderRadius: theme?.bags?.primaryButtonRounding ?? '8px',
          fontFamily: 'inherit',
        }}
        data-testid="identity-document-delete-button"
      >
        <span className="gr-m-auto gr-flex gr-items-center gr-justify-center gr-gap-2">
          {apiCallLoading ? (
            <>
              <ClipLoader size={16} color="#FFFFFF" />
              <Translate id="common.deleting" />
            </>
          ) : (
            <Translate id="common.delete" />
          )}
        </span>
      </button>

      <button
        className={`gr-cursor-pointer gr-font-medium gr-text-center gr-h-12 ${
          isMobile ? 'gr-w-48' : 'gr-min-w-200'
        } gr-text-base gr-border-0 disabled:gr-opacity-50 disabled:gr-cursor-not-allowed gr-mr-2`}
        hidden={!shouldHideDeleteButton(isSelected, values)}
        style={{
          background: theme?.bags?.primaryButtonColor ?? '#4CA7DE',
          color: theme?.bags?.primaryButtonText ?? '#FFFFFF',
          borderRadius: theme?.bags?.primaryButtonRounding ?? '8px',
          fontFamily: 'inherit',
        }}
        data-testid="identity-document-save-button"
      >
        <span className="gr-m-auto gr-flex gr-items-center gr-justify-center gr-gap-2">
          {apiCallLoading ? (
            <>
              <ClipLoader size={16} color="#FFFFFF" />
              <Translate id="common.saving" />
            </>
          ) : (
            <Translate id="common.save" />
          )}
        </span>
      </button>
      {actionStatus && (
        <span
          data-testid="identity-document-action-button-status"
          className={`gr-text-sm ${actionStatus.isError ? 'gr-text-red-100' : 'gr-text-green-600'}`}
        >
          <Translate id={actionStatus.message} />
        </span>
      )}
    </form>
  );
}

function DocumentFormInput({
  passengerId,
  selectedDocumentType,
  passengerDocs,
}: {
  passengerId: string;
  selectedDocumentType: DocumentType | undefined;
  passengerDocs: Record<DocumentType, IdentityDocument>;
}) {
  const [actionStatus, setActionStatus] = useState<ActionStatus | null>(null);
  const [apiCallLoading, setApiCallLoading] = useState(false);
  const dispatch = useDispatch();
  const selectedDocument = selectedDocumentType
    ? passengerDocs[selectedDocumentType]
    : null;

  const COUNTRIES = Object.entries(countries).map(([code, country]) => ({
    value: code,
    label: `${getEmojiFlag(code as TCountryCode)} ${country.name}`,
  }));

  if (!selectedDocument) {
    return (
      <div>
        <div tabIndex={0}>
          <Translate id="identity_document.select_document_type_description" />
        </div>
      </div>
    );
  }

  const onSubmit: SubmitHandler<IdentityDocument> = async (
    data: IdentityDocument
  ) => {
    if (!selectedDocumentType) return;
    try {
      setApiCallLoading(true);
      const identityDocumentApi = convertToApiFormat(data);
      // @ts-ignore: window is not typed
      const responseData = await window.onSaveIdentityDocument(
        passengerId,
        identityDocumentApi
      );
      if (!responseData?.passengers) {
        throw new Error('Failed to submit document');
      }

      setActionStatus({
        message: 'common.save_success',
        isError: false,
      });
      dispatch(setACIData(responseData));
    } catch (error) {
      setActionStatus({
        message: 'common.save_error',
        isError: true,
      });
    } finally {
      setApiCallLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setApiCallLoading(true);
      const selectedDocument = selectedDocumentType
        ? passengerDocs[selectedDocumentType]
        : null;

      if (!selectedDocument?.document_id) {
        console.error('No document selected or document has no ID');
        return;
      }

      // @ts-ignore: window is not typed
      const responseData = await window.onDeleteIdentityDocument(
        passengerId,
        selectedDocument.document_id
      );

      if (!responseData?.passengers) {
        throw new Error('Delete failed');
      }

      setActionStatus({
        message: 'common.delete_success',
        isError: false,
      });
      dispatch(setACIData(responseData));
    } catch (error) {
      setActionStatus({
        message: 'common.delete_error',
        isError: true,
      });
    } finally {
      setApiCallLoading(false);
    }
  };

  return (
    <div>
      <div className={selectedDocumentType === 'passport' ? '' : 'gr-hidden'}>
        <WrapForm
          isSelected={selectedDocumentType === 'passport'}
          values={passengerDocs.passport}
          onSubmit={onSubmit}
          onDelete={onDelete}
          actionStatus={actionStatus}
          apiCallLoading={apiCallLoading}
        >
          {(register, errors, isDisabled) => (
            <>
              <FormInput
                name={`firstNames`}
                label="first_names"
                register={register}
                errors={errors}
                disabled={isDisabled}
                validation={{ required: 'First names are required' }}
                testId={`passport-firstNames`}
              />
              <FormInput
                name={`surname`}
                label="surname"
                register={register}
                errors={errors}
                disabled={isDisabled}
                validation={{ required: 'Surname is required' }}
                testId={`passport-surname`}
              />
              <FormInput
                name={`gender`}
                label="gender"
                type="select"
                register={register}
                errors={errors}
                disabled={isDisabled}
                options={[
                  { value: 'male', label: 'Male' },
                  { value: 'female', label: 'Female' },
                ]}
                validation={{ required: 'Gender is required' }}
                testId={`passport-gender`}
              />
              <FormInput
                name={`documentNumber`}
                label="document_number"
                type="password"
                register={register}
                errors={errors}
                disabled={isDisabled}
                validation={{
                  required: 'Document number is required',
                  minLength: {
                    value: 6,
                    message: 'Document number must be at least 6 characters',
                  },
                }}
                testId={`passport-documentNumber`}
              />
              <FormInput
                name={`dateOfBirth`}
                label="date_of_birth"
                type="date"
                register={register}
                errors={errors}
                disabled={isDisabled}
                validation={{ required: 'Date of birth is required' }}
                testId={`passport-dateOfBirth`}
              />
              <FormInput
                name={`issuedDate`}
                label="issued_date"
                type="date"
                register={register}
                errors={errors}
                disabled={isDisabled}
                validation={{ required: 'Issued date is required' }}
                testId={`passport-issuedDate`}
              />
              <FormInput
                name={`expirationDate`}
                label="expiration_date"
                type="date"
                register={register}
                errors={errors}
                disabled={isDisabled}
                validation={{
                  required: 'Expiration date is required',
                  validate: (value: string) =>
                    isFutureDate(value) ||
                    'Expiration date must be in the future',
                }}
                testId={`passport-expirationDate`}
              />
              <FormInput
                name={`issuingCountry`}
                label="country_of_issue"
                type="select"
                register={register}
                errors={errors}
                disabled={isDisabled}
                options={COUNTRIES}
                validation={{ required: 'Country of issue is required' }}
                testId={`passport-issuingCountry`}
              />
            </>
          )}
        </WrapForm>
      </div>

      <div className={selectedDocumentType === 'visa' ? '' : 'gr-hidden'}>
        <WrapForm
          isSelected={selectedDocumentType === 'visa'}
          values={passengerDocs.visa}
          onSubmit={onSubmit}
          onDelete={onDelete}
          actionStatus={actionStatus}
          apiCallLoading={apiCallLoading}
        >
          {(register, errors, isDisabled) => (
            <>
              <FormInput
                name={`documentNumber`}
                label="document_number"
                type="password"
                register={register}
                errors={errors}
                disabled={isDisabled}
                validation={{
                  required: 'Document number is required',
                  minLength: {
                    value: 1,
                    message: 'Document number must not be empty',
                  },
                }}
                testId={`visa-documentNumber`}
              />
              <FormInput
                name={`expirationDate`}
                label="expiration_date"
                type="date"
                register={register}
                errors={errors}
                disabled={isDisabled}
                validation={{
                  required: 'Expiration date is required',
                  validate: (value: string) =>
                    isFutureDate(value) ||
                    'Expiration date must be in the future',
                }}
                testId={`visa-expirationDate`}
              />
              <FormInput
                name={`issuingCountry`}
                label="country_of_issue"
                type="select"
                register={register}
                errors={errors}
                disabled={isDisabled}
                options={COUNTRIES}
                validation={{ required: 'Country of issue is required' }}
                testId={`visa-issuingCountry`}
              />
            </>
          )}
        </WrapForm>
      </div>

      <div
        className={
          selectedDocumentType === 'destination_address' ? '' : 'gr-hidden'
        }
      >
        <WrapForm
          isSelected={selectedDocumentType === 'destination_address'}
          values={passengerDocs.destination_address}
          onSubmit={onSubmit}
          onDelete={onDelete}
          actionStatus={actionStatus}
          apiCallLoading={apiCallLoading}
        >
          {(register, errors, isDisabled) => (
            <>
              <FormInput
                name={`streetAddress1`}
                label="street_address_1"
                register={register}
                errors={errors}
                disabled={isDisabled}
                validation={{ required: 'Street address is required' }}
                testId={`destination_address-streetAddress1`}
              />
              <FormInput
                name={`city`}
                label="city"
                register={register}
                errors={errors}
                disabled={isDisabled}
                validation={{ required: 'City is required' }}
                testId={`destination_address-city`}
              />
              <FormInput
                name={`state`}
                label="state"
                register={register}
                errors={errors}
                disabled={isDisabled}
                validation={{ required: 'State is required' }}
                testId={`destination_address-state`}
              />
              <FormInput
                name={`postalCode`}
                label="postal_code"
                register={register}
                errors={errors}
                disabled={isDisabled}
                validation={{ required: 'Postal code is required' }}
                testId={`destination_address-postalCode`}
              />
              <FormInput
                name={`country`}
                label="country"
                type="select"
                register={register}
                errors={errors}
                disabled={isDisabled}
                options={COUNTRIES}
                validation={{ required: 'Country is required' }}
                testId={`destination_address-country`}
              />
            </>
          )}
        </WrapForm>
      </div>

      <div
        className={
          selectedDocumentType === 'emergency_contact' ? '' : 'gr-hidden'
        }
      >
        <WrapForm
          isSelected={selectedDocumentType === 'emergency_contact'}
          values={passengerDocs.emergency_contact}
          onSubmit={onSubmit}
          onDelete={onDelete}
          actionStatus={actionStatus}
          apiCallLoading={apiCallLoading}
        >
          {(register, errors, isDisabled) => (
            <>
              <FormInput
                name={`firstNames`}
                label="first_names"
                register={register}
                errors={errors}
                disabled={isDisabled}
                validation={{ required: 'Contact first name(s) is required' }}
                testId={`emergency_contact-firstNames`}
              />
              <FormInput
                name={`surname`}
                label="surname"
                register={register}
                errors={errors}
                disabled={isDisabled}
                validation={{ required: 'Contact surname is required' }}
                testId={`emergency_contact-surname`}
              />
              <PhoneInput
                register={register}
                errors={errors}
                disabled={isDisabled}
                testId={`emergency_contact-phone`}
              />
            </>
          )}
        </WrapForm>
      </div>
    </div>
  );
}

const PassengerForm = ({
  passenger,
  passengerIndex,
}: {
  passenger: any;
  passengerIndex: number;
}) => {
  const passengerDocuments = useSelector(
    // @ts-ignore: Redux state is not typed
    (state) => state.aci.passengerDocumentMap[passenger.passenger_id] ?? {}
  );

  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState<
    DocumentType | undefined
  >();

  const areAllDocumentsComplete = (
    docs: Record<string, IdentityDocument>
  ): boolean => {
    return Object.values(docs).every(
      (doc: IdentityDocument) => doc.document_id
    );
  };

  return (
    <div
      className={`gr-border gr-border-gray-300 gr-rounded-lg gr-mb-2 gr-bg-white`}
      data-testid={`passenger-form-${passengerIndex}`}
    >
      <div
        onClick={() => setIsExpanded(!isExpanded)}
        className={`
          gr-rounded-xl gr-text-black gr-border gr-border-solid gr-border-gray-300
          gr-flex gr-justify-between gr-items-center gr-p-4 gr-cursor-pointer
        `}
        data-testid={`passenger-form-header-${passengerIndex}`}
      >
        <div className="gr-flex gr-items-center gr-gap-3">
          <span
            className={`gr-bag-pax-name-text gr-text-gray-900 gr-text-sm gr-not-italic gr-leading-normal gr-font-bold`}
            data-testid={`passenger-name-${passengerIndex}`}
          >
            <Translate id="passenger" /> {passengerIndex + 1}
            {(passenger.first_names || passenger.surname) &&
              `: ${passenger.first_names} ${passenger.surname}`}
          </span>
        </div>
        <div className="gr-flex gr-items-center gr-gap-2">
          <span
            className={`gr-text-sm gr-font-medium gr-flex gr-items-center ${
              areAllDocumentsComplete(passengerDocuments)
                ? 'gr-text-green-600'
                : 'gr-text-yellow-500'
            }`}
            data-testid={`passenger-status-${passengerIndex}`}
          >
            <Translate
              id={
                areAllDocumentsComplete(passengerDocuments)
                  ? 'identity_document.status.complete'
                  : 'identity_document.status.incomplete'
              }
            />
          </span>
          <SelectArrow reverse={isExpanded} className={`gr-w-5 gr-h-5`} />
        </div>
      </div>

      <div
        className={`gr-p-6 gr-border-t ${isExpanded ? '' : 'gr-hidden'}`}
        data-testid={`passenger-form-content-${passengerIndex}`}
      >
        <DocumentTypeSelect
          value={selectedDocumentType}
          handleChange={(value: DocumentType) => setSelectedDocumentType(value)}
          documentTypes={passengerDocuments}
          // This is overloaded to work with the Select component, usually it's index, but here it's the number of documents completed
          current={{
            index:
              Object.values(
                passengerDocuments as Record<string, IdentityDocument>
              ).filter(
                (doc) => 'document_id' in doc && doc.document_id !== undefined
              ).length - 1,
          }}
        />

        <DocumentFormInput
          passengerId={passenger.passenger_id}
          selectedDocumentType={selectedDocumentType}
          passengerDocs={passengerDocuments}
        />
      </div>
    </div>
  );
};

const renderCheckInWindowPassedWarning = () => {
  return (
    <div
      tabIndex={0}
      className="gr-flex gr-flex-col gr-items-center gr-justify-center gr-bg-gray-50 gr-rounded-lg gr-border gr-border-gray-300 gr-p-8 gr-mb-4"
      data-testid="check-in-window-passed-warning"
    >
      <WarningIcon scale={3} fill="#495662" />
      <h3 className="gr-text-lg gr-font-medium gr-text-gray-900 gr-mb-1">
        <Translate id="identity_document.check_in_window_passed.title" />
      </h3>
      <p className="gr-text-sm gr-text-gray-500 gr-text-center">
        <Translate id="identity_document.check_in_window_passed.description" />
      </p>
    </div>
  );
};

const renderNoDataAvailableWarning = () => {
  return (
    <div
      tabIndex={0}
      className="gr-flex gr-flex-col gr-items-center gr-justify-center gr-bg-gray-50 gr-rounded-lg gr-border gr-border-gray-300 gr-p-8"
    >
      <WarningIcon scale={3} fill="#495662" />
      <h3 className="gr-text-lg gr-font-medium gr-text-gray-900 gr-mb-1">
        <Translate id="identity_document.no_data_available" />
      </h3>
      <p className="gr-text-sm gr-text-gray-500 gr-text-center">
        <Translate id="identity_document.no_data_available_description" />
      </p>
    </div>
  );
};

const IdentityDocumentForm = () => {
  // @ts-ignore: Redux state is not typed
  const aciData = useSelector((state) => state.aci.data);
  // @ts-ignore: Redux state is not typed
  const aciError = useSelector((state) => state.aci.error);
  const passengers = aciData?.passengers ?? [];

  if (aciError) {
    if (aciError.type === 'ACI_CHECK_IN_WINDOW_PASSED') {
      return renderCheckInWindowPassedWarning();
    }
    return renderNoDataAvailableWarning();
  }

  if (!aciData) {
    return renderNoDataAvailableWarning();
  }

  return (
    <div
      data-testid="identity-document-form-content"
      tabIndex={0}
      className="gr-flex gr-flex-col gr-bg-white gr-rounded-lg gr-border gr-border-gray-300"
    >
      {passengers.map((passenger: any, index: number) => (
        <PassengerForm
          key={passenger.passenger_id}
          passenger={passenger}
          passengerIndex={index}
        />
      ))}
    </div>
  );
};

export default IdentityDocumentForm;
