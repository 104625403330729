import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate, withLocalize } from 'react-localize-redux';
import { ClipLoader } from 'react-spinners';
import { initializeLocalization } from '../translations';
import IdentityDocumentForm from '../components/identity_document/IdentityDocumentForm';
import { setACIData, setVariants, setACIError } from '../redux/actions';
import IdentityDocumentHeader from '../components/identity_document/IdentityDocumentHeader';
import { Dispatch, AnyAction } from 'redux';
import {
  DocumentType,
  IdentityDocument,
  IdentityDocumentApi,
} from '../components/identity_document/identityDocumentTypes';

declare global {
  interface Window {
    onSaveIdentityDocument: (
      passengerId: string,
      document: any
    ) => Promise<any>;
    onDeleteIdentityDocument: (
      passengerId: string,
      documentId: string
    ) => Promise<any>;
  }
}

interface RootState {
  session: {
    mobile: boolean;
  };
}

interface ACIError {
  type: 'GENERIC_ERROR' | 'NO_ACI_AVAILABLE' | 'ACI_CHECK_IN_WINDOW_PASSED';
  message: string;
  originalError?: Error;
}

interface Passenger {
  passenger_id: string;
  first_names?: string;
  surname?: string;
  documents?: Record<DocumentType, IdentityDocument>;
}

interface ACIData {
  passengers: Passenger[];
  error?: ACIError;
  language?: string;
}

interface IdentityDocumentWidgetProps {
  modal?: boolean;
  fontFamily?: string;
  initialize: any;
  addTranslationForLanguage: any;
  getACIJson: Promise<ACIData | null>;
  onSaveIdentityDocument: (
    passengerId: string,
    document: IdentityDocumentApi
  ) => Promise<ACIData>;
  onDeleteIdentityDocument: (
    passengerId: string,
    documentId: string
  ) => Promise<ACIData>;
  variants?: Record<string, boolean | string>;
  setActiveLanguage: any;
  language?: string;
}

function IdentityDocumentWidget({
  modal,
  fontFamily,
  initialize,
  addTranslationForLanguage,
  getACIJson,
  onSaveIdentityDocument,
  onDeleteIdentityDocument,
  variants = {},
  setActiveLanguage,
  language,
}: IdentityDocumentWidgetProps) {
  window.onSaveIdentityDocument = onSaveIdentityDocument;
  window.onDeleteIdentityDocument = onDeleteIdentityDocument;
  const dispatch = useDispatch<Dispatch<AnyAction>>();
  const [loading, setLoading] = useState(true);

  const isMobile = useSelector((state: RootState) => state.session.mobile);

  const processACIData = useCallback(async () => {
    try {
      const data = await getACIJson;

      const booleanizedVariants = Object.entries(variants).reduce<
        Record<string, boolean>
      >(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value === 'true' || value === true,
        }),
        {}
      );

      dispatch(setVariants(booleanizedVariants));

      if (data?.error) {
        dispatch(setACIError(data.error));
      }

      dispatch(setACIData(data));

      const action = setActiveLanguage(language || data?.language || 'en-US');
      dispatch(action as unknown as AnyAction);

      setLoading(false);
    } catch (error) {
      console.error('Error in processACIData:', error);
      dispatch(
        setACIError({
          type: 'PROCESS_ERROR',
          message: 'Failed to process ACI data',
          originalError: error,
        })
      );
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, getACIJson, variants, language]);

  useEffect(() => {
    initializeLocalization(initialize, addTranslationForLanguage);
    processACIData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processACIData]);

  if (loading) {
    return (
      <div tabIndex={0} className="gr-flex gr-items-center gr-justify-center">
        <ClipLoader />
      </div>
    );
  }

  return (
    <div
      tabIndex={0}
      style={{ fontFamily }}
      className={`
        gr-z-60
        gr-flex 
        gr-flex-col 
        gr-border 
        gr-border-solid 
        gr-border-gray-300 
        gr-rounded-lg
        gr-overflow-y-auto
        ${modal ? 'gordian-modal' : 'gordian-embedded'}
        ${isMobile ? 'gr-h-full gr-w-full' : 'gr-w-3/4 lg:gr-w-1/2'}
    `}
    >
      <IdentityDocumentHeader isMobile={isMobile} />
      <div className="gr-p-8">
        <div
          className="gr-text-2xl gr-font-bold gr-mb-2"
          data-testid="identity-document-widget-title"
        >
          <Translate id="identity_document.title" />
        </div>
        <div
          className="gr-text-sm gr-text-gray-500 gr-mb-8"
          data-testid="identity-document-widget-description"
        >
          <Translate id="identity_document.description" />
        </div>
        <IdentityDocumentForm />
      </div>
    </div>
  );
}

export default withLocalize(IdentityDocumentWidget as any);
