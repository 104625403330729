import React from 'react';

function IdentityDocumentIcon({ scale = 1, fill = '#3498db' }) {
  const size = `${16 * scale}`;
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38.24,1H9.14c-2.44,0-4.41,1.99-4.41,4.43v7.05h-.37c-.47,0-.84,.37-.84,.84v5.75c0,.47,.37,.84,.84,.84h.37v1.65h-.37c-.47,0-.84,.37-.84,.84v5.75c0,.47,.37,.84,.84,.84h.37v29.58c0,2.44,1.97,4.43,4.41,4.43h29.1c2.44,0,4.43-1.99,4.43-4.43v-9.92c-.31,.07-.64,.1-.97,.1h-2.39c-.22,0-.44-.02-.66-.06v4.63c-.71,.08-1.26,.67-1.26,1.4s-.49,1.28-1.1,1.28H11.09c-.6,0-1.09-.57-1.09-1.28s-.56-1.32-1.26-1.4V10.68c.7-.08,1.26-.67,1.26-1.4s.49-1.28,1.09-1.28h25.2c.61,0,1.1,.57,1.1,1.28s.55,1.32,1.26,1.4v4.63c.21-.04,.43-.06,.65-.06h2.4c.33,0,.66,.03,.97,.1V5.43c0-2.44-1.99-4.43-4.43-4.43Z"
          fill="#495662"
        />
        <path
          d="M26.8,5.77h-6.21c-.7,0-1.27-.57-1.27-1.27s.57-1.26,1.27-1.26h6.21c.7,0,1.27,.56,1.27,1.26s-.57,1.27-1.27,1.27Z"
          fill="#495662"
        />
        <path
          d="M59.69,30.41l-1.11-.85c-1.3-.99-2.83-1.63-4.47-1.89l-7.28-.08-3.65-8.38c-.11-.26-.29-.48-.51-.64-.27-.2-.61-.32-.97-.32h-2.4c-.28,0-.52,.15-.65,.36-.1,.14-.14,.32-.12,.5l.12,.98,.98,7.97-.98,.11-11.35,1.33-2.12-6.15-2.63,.02,.86,7.71c-.07,.28-.15,.6-.15,.92s.06,.64,.17,.94l-.88,7.71,2.63,.02,2.12-6.16,11.35,1.32,.96,.11-.96,8.11-.1,.84c-.02,.18,.02,.35,.1,.49,.13,.22,.37,.37,.66,.37h2.39c.36,0,.7-.12,.97-.32,.22-.16,.4-.38,.51-.64l3.65-8.38,7.3-.08c1.62-.26,3.14-.9,4.47-1.89l1.1-.86c1.04-.8,1.03-2.38,0-3.17Z"
          fill={fill}
        />
        <path
          d="M19.54,24.26h-5.35c-.61,0-1.11,.5-1.11,1.11s.5,1.11,1.11,1.11h5.35c.61,0,1.11-.5,1.11-1.11s-.5-1.11-1.11-1.11Z"
          fill={fill}
        />
        <path
          d="M17.84,30.9c.61,0,1.11-.5,1.11-1.11s-.5-1.11-1.11-1.11h-2.12c-.61,0-1.11,.5-1.11,1.11s.5,1.11,1.11,1.11h2.12Z"
          fill={fill}
        />
        <path
          d="M18.95,34.21c0-.61-.5-1.11-1.11-1.11h-5.58c-.61,0-1.11,.5-1.11,1.11s.5,1.11,1.11,1.11h5.58c.61,0,1.11-.5,1.11-1.11Z"
          fill={fill}
        />
        <path
          d="M19.53,37.52h-3.8c-.61,0-1.11,.5-1.11,1.11s.5,1.11,1.11,1.11h3.8c.61,0,1.11-.5,1.11-1.11s-.5-1.11-1.11-1.11Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}

export default IdentityDocumentIcon;
