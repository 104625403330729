import React from 'react';
import SelectArrow from './SelectArrow';
import { Translate } from 'react-localize-redux';

function GenericSelect({ props, selectId, seat, outOfText }) {
  return (
    <div
      className="gordian-select gr-text-title gr-bg-white gr-w-3/6 gr-h-full gr-relative gr-border gr-text-xs sm:gr-text-sm md:gr-text-base"
      id={props.id}
    >
      <select
        onChange={(event) => props.handleChange(event.target.value)}
        className="gr-block gr-appearance-none md:text-align-last-center gr-text-center gr-w-full gr-h-full gr-textAlignLast gr-bg-white gr-font-normal gr-rounded-none gr-pl-6 gr-pr-12 sm:gr-pr-4 gr-py-2 gr-leading-tight"
        value={props.value}
        id={selectId}
        data-testid={props['data-testid']}
      >
        {props.children}
      </select>
      <div
        aria-hidden="true"
        className="gr-pointer-events-none gr-text-secondary-text gr-text-xs sm:gr-text-sm md:gr-text-base gr-absolute gr-inset-y-0 gr-right-25 sm:gr-right-100 gr-flex gr-items-center gr-pr-2"
      >
        {seat ? seat : ''}
      </div>
      <div
        aria-hidden="true"
        className="gr-pointer-events-none gr-absolute gr-inset-y-0 gr-right-0 gr-flex gr-items-center gr-px-2"
      >
        <div className="gr-hidden sm:gr-block gr-text-xs  gr-text-secondary-text gr-mr-1 sm:gr-mr-2">
          {outOfText}
        </div>
        <SelectArrow />
      </div>
    </div>
  );
}

function UberSelect({ props, selectId, seat, outOfText, translatedDirection }) {
  return (
    <div
      className="gr-rounded-lg gr-bg-white gr-w-3/6 gr-h-full gr-relative gr-border gr-text-xs sm:gr-text-sm md:gr-text-base"
      id={props.id}
    >
      <select
        onChange={(event) => props.handleChange(event.target.value)}
        className="gr-block gr-appearance-none gr-rounded-lg gr-w-full gr-h-full gr-textAlignLast gr-bg-white gr-font-normal gr-pl-6 gr-pr-12 sm:gr-pr-4 gr-py-2 gr-leading-tight"
        value={props.value}
        id={selectId}
        data-testid={props['data-testid']}
      >
        {props.children}
      </select>
      <div
        className={`gr-pointer-events-none gr-text-secondary-text gr-text-sm gr-absolute gr-inset-y-0 gr-pl-6 gr-mt-10`}
      >
        {props.value != null ? translatedDirection : ''}
      </div>

      <div
        className={`gr-pointer-events-none gr-text-secondary-text gr-text-sm gr-absolute gr-inset-y-0 gr-pl-6 gr-mt-10`}
      >
        {seat ? (
          seat
        ) : props.selectingPassenger === true ? (
          <Translate id="selecting" />
        ) : (
          ''
        )}
      </div>

      <div
        aria-hidden="true"
        className="gr-pointer-events-none gr-absolute gr-inset-y-0 gr-right-0 gr-flex gr-items-center gr-px-2"
      >
        <div className="gr-hidden sm:gr-block gr-text-xs  gr-text-secondary-text gr-mr-1 sm:gr-mr-2">
          {outOfText}
        </div>
        <SelectArrow />
      </div>
    </div>
  );
}

export default function Select(props) {
  const { current = {}, currentSeat = {}, locale, length } = props;
  const { index = 0 } = current;
  const { seat = '' } = currentSeat;
  let selectId = props.id ? `${props.id}-select` : null;

  const formatter = new Intl.NumberFormat(locale);
  const outOfText = `${formatter.format(index + 1)} / ${formatter.format(
    length
  )}`;

  switch (props.brand) {
    case 'uber':
      return (
        <UberSelect
          props={props}
          selectId={selectId}
          seat={seat}
          outOfText={outOfText}
          translatedDirection={props.translatedDirection}
        >
          {' '}
          {props.children}{' '}
        </UberSelect>
      );
    default:
      return (
        <GenericSelect
          props={props}
          selectId={selectId}
          seat={seat}
          outOfText={outOfText}
        >
          {' '}
          {props.children}{' '}
        </GenericSelect>
      );
  }
}
