import {
  CpfIdDocumentApi,
  DestinationAddressDocumentApi,
  EmergencyContactDocumentApi,
  IdentityDocument,
  IdentityDocumentApi,
  KnownTravellerDocumentApi,
  NationalIdDocumentApi,
  PassportDocumentApi,
  RedressDocumentApi,
  VisaDocumentApi,
} from '../../components/identity_document/identityDocumentTypes';

export const convertToApiFormat = (
  doc: IdentityDocument
): IdentityDocumentApi => {
  if (!doc || !doc.documentType) {
    throw new Error('Invalid document');
  }

  const baseFields = {
    document_id: doc.document_id,
    document_type: doc.documentType,
  };

  switch (doc.documentType) {
    case 'passport': {
      const apiDocument: PassportDocumentApi = {
        ...baseFields,
        document_type: 'passport',
        date_of_birth: doc.dateOfBirth,
        document_number: doc.documentNumber,
        expiration_date: doc.expirationDate,
        first_names: doc.firstNames,
        gender: doc.gender,
        issued_date: doc.issuedDate,
        issuing_country: doc.issuingCountry,
        nationality: doc.nationality,
        surname: doc.surname,
      };
      return apiDocument;
    }
    case 'visa': {
      const apiDocument: VisaDocumentApi = {
        ...baseFields,
        document_type: 'visa',
        document_number: doc.documentNumber,
        expiration_date: doc.expirationDate,
        issuing_country: doc.issuingCountry,
      };
      return apiDocument;
    }
    case 'destination_address': {
      const apiDocument: DestinationAddressDocumentApi = {
        ...baseFields,
        document_type: 'destination_address',
        street_address_1: doc.streetAddress1,
        city: doc.city,
        state: doc.state,
        country: doc.country,
        postal_code: doc.postalCode,
      };
      return apiDocument;
    }
    case 'emergency_contact': {
      const apiDocument: EmergencyContactDocumentApi = {
        ...baseFields,
        document_type: 'emergency_contact',
        first_names: doc.firstNames,
        surname: doc.surname,
        phone_number_country_code: doc.phoneNumberCountryCode,
        phone_number: doc.phoneNumber,
      };
      return apiDocument;
    }
    case 'known_traveller': {
      const apiDocument: KnownTravellerDocumentApi = {
        ...baseFields,
        document_type: 'known_traveller',
        document_number: doc.documentNumber,
      };
      return apiDocument;
    }
    case 'redress': {
      const apiDocument: RedressDocumentApi = {
        ...baseFields,
        document_type: 'redress',
        document_number: doc.documentNumber,
      };
      return apiDocument;
    }
    case 'national_id': {
      const apiDocument: NationalIdDocumentApi = {
        ...baseFields,
        document_type: 'national_id',
        document_number: doc.documentNumber,
        expiration_date: doc.expirationDate,
      };
      return apiDocument;
    }
    case 'cpf_id': {
      const apiDocument: CpfIdDocumentApi = {
        ...baseFields,
        document_type: 'cpf_id',
        document_number: doc.documentNumber,
      };
      return apiDocument;
    }
    default:
      // @ts-ignore
      throw new Error(`Unsupported document type: ${doc.documentType}`);
  }
};

export const isFutureDate = (value: string): boolean => {
  if (!value) return false;
  const today = new Date();
  const todayStr = today.toISOString().split('T')[0];
  return value >= todayStr;
};
