import React from 'react';
import Select from '../shared/Select';
import { Translate } from 'react-localize-redux';
import { DocumentType, IdentityDocument } from './identityDocumentTypes';

export default function DocumentTypeSelect({
  value,
  handleChange,
  documentTypes = {} as Record<DocumentType, IdentityDocument>,
  current,
}: {
  value: DocumentType | undefined;
  handleChange: (value: DocumentType) => void;
  documentTypes: Record<DocumentType, IdentityDocument>;
  current: {
    index: number;
  };
}) {
  return (
    <div className="gr-mb-4" data-testid="document-type-select-container">
      <label
        className="gr-block gr-mb-2 gr-text-sm gr-font-medium gr-text-gray-700"
        data-testid="document-type-label"
      >
        <Translate id="identity_document.document_type" />
      </label>
      <Select
        brand="uber"
        value={value ?? ''}
        handleChange={handleChange}
        length={Object.keys(documentTypes).length}
        current={current}
        data-testid="input-documentType"
      >
        <option value="" disabled data-testid="document-type-placeholder">
          <Translate id="identity_document.select_type" />
        </option>
        {Object.values(documentTypes).map(({ documentType, label }) => (
          <option
            key={documentType}
            value={documentType}
            data-testid={`document-type-option-${documentType}`}
          >
            {label}
          </option>
        ))}
      </Select>
    </div>
  );
}
