import React from 'react';
import { connect } from 'react-redux';
import { Translate, getTranslations } from 'react-localize-redux';
import styled from 'styled-components';
import { showModal, setActiveWidget } from '../../redux/actions';
import { ClipLoader } from 'react-spinners';
import IdentityDocumentIcon from './IdentityDocumentIcon';

const TriggerContainer = styled.section`
  margin: 20px;
  background: #ffffff;
  padding: 25px;
  font-family: Roboto;
  border: 2px solid #e1e8ee;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 18px;
  display: flex;
`;

const IdentityDocumentTrigger = ({
  aciData,
  aciError,
  showModal,
  setActiveWidget,
  translations,
  theme,
}) => {
  const hasAciData = Boolean(aciData?.passengers);
  const translationsLoaded = Boolean(Object.keys(translations).length);
  const isCheckInWindowPassed = aciError?.type === 'ACI_CHECK_IN_WINDOW_PASSED';
  const hasError = Boolean(aciError);

  const buttonStyles = {
    width: '200px',
    height: '50px',
    color: '#ffffff',
    background: '#2990e6',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'center',
    borderRadius: '5px',
    cursor: 'pointer',
    ...(theme?.trigger ?? {}),
    ...(theme?.identityDocumentTrigger ?? {}),
  };

  if (!translationsLoaded) return <></>;

  const getDescriptionTranslationId = () => {
    if (isCheckInWindowPassed) {
      return 'identity_document.check_in_window_passed.description';
    }
    if (hasError) {
      return 'identity_document.load_failed';
    }
    if (hasAciData) {
      return 'identity_document.load_success';
    }
    return 'identity_document.load_failed';
  };

  return (
    <TriggerContainer
      className="gr-block sm:gr-flex"
      data-testid="identity-document-trigger"
    >
      <div className="gr-flex-1">
        <div className="gr-flex">
          <IdentityDocumentIcon
            scale={2}
            data-testid="identity-document-widget-trigger-icon"
          />
          <div>
            <h2
              className="gr-m-0 gr-ml-4 gr-text-primary"
              data-testid="identity-document-widget-trigger-title"
            >
              <Translate id="identity_document.title" />
            </h2>
            <p
              className="gr-mx-4 gr-text-gray-600"
              data-testid="identity-document-widget-trigger-description"
            >
              <Translate id={getDescriptionTranslationId()} />
            </p>
          </div>
        </div>
      </div>
      <div className="gr-flex-1 gr-flex gr-items-center sm:gr-justify-end gr-justify-center gr-w-full sm:gr-w-full gr-overflow-auto">
        {!hasError &&
          (hasAciData ? (
            <button
              onClick={() => {
                showModal(true);
                setActiveWidget('automatic_check_in');
              }}
              id="gordian-identity-document-selection"
              className="gr-text-center gr-border-0 gr-rounded-lg"
              style={buttonStyles}
              data-testid="identity-document-widget-trigger-add-button"
            >
              <Translate id="identity_document.add_documents" />
            </button>
          ) : (
            <button
              disabled
              id="gordian-identity-document-selection"
              className="gr-text-center gr-border-0 gr-rounded-lg"
              style={buttonStyles}
              data-testid="identity-document-widget-trigger-add-button"
            >
              <ClipLoader
                size={25}
                color={'white'}
                data-testid="identity-document-widget-trigger-loader"
              />
            </button>
          ))}
      </div>
    </TriggerContainer>
  );
};

const mapStateToProps = (state) => ({
  aciData: state.aci.data,
  aciError: state.aci.error,
  theme: state.session.theme,
  translations: getTranslations(state.localize),
});

const mapDispatchToProps = {
  showModal,
  setActiveWidget,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdentityDocumentTrigger);
