import React from 'react';

import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';

import MoonLoader from 'react-spinners/ClipLoader';
import SeatRecline from '../shared/SeatRecline';
import { showModal, setActiveWidget } from '../../redux/actions';
import styled from 'styled-components';
import { getSeatTriggerSubtitle } from '../../utils/trigger';
import { getTranslations, Translate } from 'react-localize-redux';

const SeatMapTrigger = styled.section`
  margin: 20px;
  background: #ffffff;
  padding: 25px;
  font-family: Roboto;
  border: 2px solid #e1e8ee;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 18px;
  display: flex;
`;

const Trigger = ({
  seats,
  flights,
  showModal,
  hasSeats,
  setActiveWidget,
  textOverrides,
  translations,
  theme,
}) => {
  const triggerSubtitle = getSeatTriggerSubtitle(hasSeats, textOverrides);
  const subtitle = triggerSubtitle.includes('seat_load_') ? (
    <Translate id={triggerSubtitle} />
  ) : (
    triggerSubtitle
  );

  const translationsLoaded = !!Object.keys(translations).length;

  const buttonStyles = {
    width: '200px',
    height: '50px',
    color: '#ffffff',
    background: '#2990e6',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '140%',
    textAlign: 'center',
    borderRadius: '5px',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'center',
    ...(theme?.trigger ?? {}),
    ...(theme?.seatMapTrigger ?? {}),
  };

  return translationsLoaded ? (
    <SeatMapTrigger className="gr-block sm:gr-flex">
      <div className="g-flex-1">
        <div className="gr-flex">
          <SeatRecline />
          <div>
            <h2 className="gr-m-0 gr-ml-4 gr-text-primary">
              <Translate id="seat_selection" />
            </h2>
            {seats.length > 0 ? (
              seats.map(
                ({ name = '', seat = '', first_names = '', surname = '' }) => {
                  const paxName = `${first_names} ${surname}`;
                  const [, flightNumber] = name.split(', ');
                  return (
                    <p className="gr-mx-4 gr-text-gray-600" key={uuidv4()}>
                      {flightNumber
                        ? flightNumber.replaceAll(' ', '') + ' - '
                        : ''}
                      {paxName}: {seat}
                    </p>
                  );
                }
              )
            ) : (
              <p className="gr-mx-4 gr-text-gray-600">{subtitle}</p>
            )}
          </div>
        </div>
      </div>
      <div
        id="gordian-select-seat-button-container"
        className="gr-flex-1 gr-flex gr-items-center sm:gr-justify-end gr-justify-center gr-w-full sm:gr-w-full  gr-overflow-auto"
      >
        {hasSeats ? (
          <button
            disabled={!(flights.length > 0)}
            onClick={() => {
              showModal(true);
              setActiveWidget('seatmap');
            }}
            id="gordian-seat-selection"
            className="gr-text-center gr-border-0 gr-rounded-lg"
            style={buttonStyles}
          >
            {flights.length > 0 || process.env.STORYBOOK_USED ? (
              <Translate id="select_seat" />
            ) : (
              <MoonLoader size={25} color={'white'} />
            )}
          </button>
        ) : null}
      </div>
    </SeatMapTrigger>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  seats: state.basket.seats,
  hasSeats: state.session.hasSeats,
  flights: state.itinerary.flights,
  textOverrides: state.session.textOverrides,
  theme: state.session.theme,
  translations: getTranslations(state.localize),
});

const mapDispatchToProps = {
  showModal,
  setActiveWidget,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trigger);
