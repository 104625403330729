import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../shared/Footer';
import Button from '../shared/Button';
import CategoriesList from './CategoriesList';

import { Translate } from 'react-localize-redux';
import { showCategories } from '../../redux/actions';

import { ReactComponent as Close } from '../../svg/close.svg';
import MediaQuery from 'react-responsive';
import { rtlLangs } from '../../translations/rtl-langs';

class CategoriesContainer extends Component {
  render() {
    const {
      currentSeatmap,
      categories,
      showCategories,
      theme,
      relativeWidthRatio,
      locale,
    } = this.props;
    const CATEGORIES_DISPLAY_BARRIER = 1119;

    return (
      <MediaQuery maxWidth={CATEGORIES_DISPLAY_BARRIER / relativeWidthRatio}>
        {(matches) => {
          const hiddenCategoriesModal = matches ? '' : 'lg:gr-hidden';
          return (
            <div
              className={`gordian-modal gr-w-full ${hiddenCategoriesModal}`}
              style={{ zIndex: 103, position: 'absolute' }}
              dir={rtlLangs.includes(locale) ? 'rtl' : 'ltr'}
            >
              <div className="gordian-modal-guts gr-block">
                <header className="gr-flex gr-px-4 gr-border-b gr-text-gray-800 gr-h-20 gr-w-full gr-border-grey-500 gr-items-center gr-justify-between">
                  <div>
                    <Translate id="seat_legend" />
                  </div>
                  <div
                    onClick={() => {
                      showCategories(false);
                    }}
                  >
                    <Close alt="Close" className="gr-mx-2 gr-cursor-pointer" />
                  </div>
                </header>
                <div className="gr-block gr-p-5 gr-relative gr-h-modal gr-w-full gr-scrolling-touch gr-overflow-y-auto gr-overflow-x-hidden">
                  <CategoriesList
                    currentSeatmap={currentSeatmap}
                    categories={categories}
                    simpleVariant={false}
                    theme={theme}
                    hideDetails={true}
                  />
                </div>
                <Footer>
                  <div className="gr-w-full gr-px-4">
                    <Button
                      id="close-categories"
                      handleClick={() => showCategories(false)}
                      primary
                    >
                      <Translate id="close"></Translate>
                    </Button>
                  </div>
                </Footer>
              </div>
            </div>
          );
        }}
      </MediaQuery>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.categories,
  loading: state.session.loading,
  currentSeatmap: state.itinerary.currentSeatmap,
  relativeWidthRatio: state.session.relativeWidthRatio,
  locale: state.session.locale,
  theme: state.session.theme,
});

const mapDispatchToProps = {
  showCategories,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesContainer);
