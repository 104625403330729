import React from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { showModal } from '../../redux/actions';
import { ReactComponent as CloseButton } from '../../svg/baggage-close.svg';
import { Translate } from 'react-localize-redux';

function IdentityDocumentHeader({ isMobile }: { isMobile: boolean }) {
  const dispatch: Dispatch = useDispatch();
  return (
    <>
      <div
        className={`gr-mt-5 gr-mb-5 gr-ml-4 ${isMobile ? 'gr-mr-4' : 'gr-mr-8'}`}
        data-testid="identity-document-header"
      >
        <div className="gr-flex gr-justify-between gr-items-center">
          <div />
          {isMobile ? (
            <div
              className="gr-leading-normal gr-text-gray-900 gr-text-base gr-not-italic gr-font-bold"
              data-testid="identity-document-mobile-title"
            >
              <Translate id="identity_document.title" />
            </div>
          ) : null}
          <CloseButton
            id="identity-document-close-button"
            className="gr-cursor-pointer"
            onClick={() => {
              dispatch(showModal(false));
            }}
            data-testid="identity-document-close-button"
          />
        </div>
      </div>
      <hr
        className="gr-bg-gray-400 gr-border-gray-200 gr-border-0 gr-m-0"
        style={{ minHeight: '1px' }}
        data-testid="identity-document-header-divider"
      />
    </>
  );
}

export default IdentityDocumentHeader;
