/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import BaggageWidget from './BaggageWidget';
import SeatmapWidget from './SeatmapWidget';
import BaggageWidgetNew from './BaggageWidget2';
import IdentityDocumentWidget from './IdentityDocumentWidget';

const DEFAULT_WIDGET_VERSIONS = {
  bags: 1,
  seats: 1,
};

class WidgetSelector extends Component {
  render() {
    const { activeWidget, allowProducts, widgetVersions } = this.props;
    const updatedWidgetVersions = {
      ...DEFAULT_WIDGET_VERSIONS,
      ...widgetVersions,
    };
    const { bags: bagWidgetVersion } = updatedWidgetVersions;

    switch (activeWidget) {
      case 'seatmap':
        return <SeatmapWidget {...this.props} />;
      case 'baggage':
        if (bagWidgetVersion === 1) {
          return <BaggageWidget {...this.props} />;
        } else {
          return <BaggageWidgetNew {...this.props} />;
        }
      case 'automatic_check_in':
        console.log('In Widget Selector: automatic_check_in');
        return <IdentityDocumentWidget {...this.props} />;
      default:
        // When the application first loads, we use the default case to "warm up" the widgets.
        // This allows them to load quicker and set their respective data JSONs (baggage, seatmap).
        // Admittedly, this feels a bit hacky. Should investigate more transparent method.
        return (
          <>
            {allowProducts.includes('seats') && (
              <SeatmapWidget {...this.props} />
            )}
            {allowProducts.includes('bags') &&
              (bagWidgetVersion === 1 ? (
                <BaggageWidget {...this.props} />
              ) : (
                <BaggageWidgetNew {...this.props} />
              ))}
            {allowProducts.includes('automatic_check_in') && (
              <IdentityDocumentWidget {...this.props} />
            )}
          </>
        );
    }
  }
}

const mapStateToProps = (state) => ({
  activeWidget: state.session.activeWidget,
});

const ModalLocalize = withLocalize(WidgetSelector);

export default connect(mapStateToProps)(ModalLocalize);
