import React, { Component } from 'react';
import { captureException, captureMessage } from '@sentry/react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import {
  languageConfigurations,
  initializeLocalization,
} from '../translations';
import { getSelectedCompartment } from '../components/seatmap/UpsellUtils';
import {
  setItinerary,
  setFlights,
  setSeatCategories,
  setCurrentPassenger,
  setPassengers,
  createBasketId,
  setLoading,
  isMobile,
  showModal,
  setVariants,
  setShouldHidePrice,
  setBasket,
  setShouldAbbreviateSeatPrice,
  showCategories,
  setSegmentColumns,
  setPriceAdjustment,
  setPriceTextPrepend,
  setUniqueCharacteristics,
  setIsEnhanced,
  addUpsellOptions,
  setSegmentsCurrentCompartment,
  setSegmentsInitialCompartment,
  setRelativeWidthRatio,
  setOfferedProducts,
  addRestrictions,
  hasSeats,
  setLocale,
} from '../redux/actions';
import { currenciesToRound } from '../utils/formatPrice';
import { calculateConvenienceFeeVariants } from '../utils/calculateConvenienceFeeVariants';

import SeatingChoice from '../components/seatmap/seatingChoice/SeatingChoice';
import SeatMapStepSelector from '../components/seatmap/SeatMapStepSelector';
import CloseButton from '../components/shared/CloseButton';
import ThemedLoader from '../themes/loaders/ThemedLoader';
import { convertFromRawToV2_2Format } from './formatConverter';
import { setDefaultPassengers } from '../utils/setDefaultPassengers';
import SeatmapContainer from '../components/seatmap/SeatmapContainer';

const MOBILE_WIDTH_THRESHOLD = 768;

const ABBREVIATED_CURRENCIES = ['USD', 'CAD', 'MXN'];

const SEATMAP_TIMEOUT = 30000; //30s
const SEATING_CHOICE_TIMEOUT = 30000; //30s

const shouldHideSeatPrice = (categories, isMobile) =>
  Object.values(Object.values(categories))
    .flat()
    .map((x) => Object.values(Object.values(x)))
    .map((x) => Object.values(x))
    .flat()
    .map((x) => Object.values(x['price_range']))
    .flat()
    .map((x) => x['end_price'])
    .some((x) => {
      const total = x['total'] / Math.pow(10, x['decimal_places']);
      const formatted = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: x['currency'],
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(total);
      return (
        (((formatted.length > 4 && isMobile) || formatted.length > 6) &&
          !ABBREVIATED_CURRENCIES.includes(x['currency'])) ||
        currenciesToRound(x['currency'])
      );
    });

const booleanizeVariants = (variants) => {
  const booleanizedVariants = Object.assign({}, variants);

  for (const variant in booleanizedVariants) {
    const value = booleanizedVariants[variant]?.toString()?.toLowerCase();
    if (['true', 'false', 'excluded'].includes(value)) {
      booleanizedVariants[variant] = value === 'true';
    }
  }

  return booleanizedVariants;
};

const shouldAbbreviateSeatPrice = (categories) =>
  Object.values(Object.values(categories))
    .flat()
    .map((x) => Object.values(Object.values(x)))
    .map((x) => Object.values(x))
    .flat()
    .map((x) => Object.values(x['price_range']))
    .flat()
    .map((x) => x)
    .some((x) => ABBREVIATED_CURRENCIES.includes(x['end_price']['currency']));

class Modal extends Component {
  constructor(props) {
    super(props);
    const width = typeof window !== 'undefined' ? window.innerWidth : 1200;
    this.state = {
      width: width,
      isMobile: width <= MOBILE_WIDTH_THRESHOLD,
      showSeatMap: true,
      error: false,
    };
  }

  /// This method creates a flat map of all of the seats.
  /// This is used for converting the accepted_products array from corev1
  /// into a pre-filled basket.
  // This is not the cheapest function on earth to run, and we also don't want
  // to worry about any potential errors from it isn't necessary.
  // Since this is only used for the cause of basket persistence,
  // we skip it and return an empty list if there aren't any accepted products.
  getBasketData = (
    seatmapsBySegment,
    passengers,
    products,
    acceptedProducts
  ) => {
    const acceptedSeatsData = [];
    if (acceptedProducts && acceptedProducts.length > 0) {
      for (const segment_id in seatmapsBySegment) {
        const seatmap = seatmapsBySegment[segment_id];
        if (seatmap.available) {
          try {
            seatmap.decks
              .flat()
              .map((deck) => deck.compartments)
              .flat()
              .map((compartment) => compartment.seat_rows)
              .flat()
              .map((seat_row) => seat_row.row_groups)
              .flat()
              .flat()
              .forEach((seat) => {
                const matchingAcceptedProduct = acceptedProducts.find(
                  (acceptedProduct) =>
                    acceptedProduct.product_id === seat.product_id
                );
                if (matchingAcceptedProduct) {
                  // Get passenger data
                  const currentPassenger = passengers.find(
                    (passenger) =>
                      passenger.passenger_id ===
                      matchingAcceptedProduct.passenger_id
                  );
                  currentPassenger.api_passenger_id =
                    matchingAcceptedProduct.api_passenger_id;

                  const selectedProduct =
                    products.seat[matchingAcceptedProduct.product_id]; // Get product data
                  const matchingSeat = {
                    selectedSeat: seat,
                    currentPassenger: currentPassenger,
                    currentSeatmap: segment_id,
                    selectedProduct: selectedProduct,
                  };
                  acceptedSeatsData.push(matchingSeat);
                }
              });
          } catch (err) {
            captureException(err);
            captureMessage('getBasketData error');
            return [];
          }
        }
      }
    }
    return acceptedSeatsData;
  };

  basketToAcceptedProducts(basket, seatJson) {
    function productInSearchResults(productId, resultsAsString) {
      return resultsAsString.search(productId);
    }

    const acceptedProducts = [];
    for (const basket_id in basket) {
      const basketProduct = basket[basket_id];
      const product_id = basketProduct['product_id'];
      const passenger_id = basketProduct['product_details']['passenger_id'];

      const product = {
        product_id,
        passenger_id,
      };
      if (
        basketProduct.product_type === 'seat' &&
        passenger_id &&
        productInSearchResults(product_id, JSON.stringify(seatJson))
      )
        acceptedProducts.push(product);
    }
    return acceptedProducts;
  }

  getUniqueCharacteristics(data) {
    const characteristics = data.results.flight?.unique_characteristics;
    return characteristics === undefined ? [] : characteristics;
  }

  isEnhanced(uniqueCharacteristics) {
    return (
      uniqueCharacteristics.includes('electronic_connection') ||
      uniqueCharacteristics.includes('individual_screen')
    );
  }

  seatingChoiceSearchFailed = () => {
    hasSeats(false);
    this.setState({
      error: true,
      showSeatMap: true,
    });
    showModal(false);
  };

  seatmapSearchFailed = () => {
    const variants = this.props.variants ?? {};
    const SEATING_CHOICE_UI_FLAG = 'SEATING_CHOICE_UI';
    const SEATING_CHOICE_UI_FLAG_ENABLED = 'ENABLED';

    if (
      SEATING_CHOICE_UI_FLAG in variants &&
      variants[SEATING_CHOICE_UI_FLAG] === SEATING_CHOICE_UI_FLAG_ENABLED
    ) {
      this.setState({
        showSeatMap: false,
      });
      this.processSeatingChoiceData();
    } else {
      hasSeats(false);
      showModal(false);
      this.setState({
        error: true,
      });
    }
  };

  processSeatingChoiceData() {
    const {
      setFlights,
      setOfferedProducts,
      setCurrentPassenger,
      setPassengers,
      setPriceAdjustment,
      setPriceTextPrepend,
      createBasketId,
      isMobile,
      setActiveLanguage,
      priceAdjustment = {
        factor: 1.0,
        name: '',
      },
      priceTextPrepend = '',
      setUniqueCharacteristics,
      setLocale,
    } = this.props;

    this.promiseTimeout(
      this.props.getSeatingChoice,
      SEATING_CHOICE_TIMEOUT
    ).then(
      (data) => {
        if (
          !data ||
          data.status === 'failed' ||
          data.product_status?.seating_choice?.status === 'failed'
        ) {
          this.seatingChoiceSearchFailed();
          return;
        }

        const { search_id, language = 'en-US', passengers = [] } = data;
        const { products = {}, itineraries = [] } = data.results;
        const { journeys = [] } = itineraries[0];

        const legs = journeys.reduce((previousValue, currentValue) => {
          const { segments = [] } = currentValue;
          return [...previousValue, ...segments];
        }, []);

        setDefaultPassengers(passengers, languageConfigurations, language);
        if (passengers.length >= 1) setCurrentPassenger(passengers[0]);
        setActiveLanguage(language);
        setLocale(language);
        isMobile(this.state.isMobile);
        setOfferedProducts(products);

        setFlights({
          legs,
          segmentIndex: this.props.segmentIndex,
        });
        createBasketId(search_id);
        setPassengers(passengers);
        setPriceAdjustment(priceAdjustment);
        setPriceTextPrepend(priceTextPrepend);
        setUniqueCharacteristics(this.getUniqueCharacteristics(data));
      },
      () => {
        this.seatingChoiceSearchFailed();
      }
    );
  }

  promiseTimeout = (prom, time) => {
    let timer;

    return Promise.race([
      prom,
      new Promise((_r, rej) => (timer = setTimeout(rej, time))),
    ])
      .then((result) => {
        clearTimeout(timer);
        return result;
      })
      .catch((err) => {
        clearTimeout(timer);
        return err;
      });
  };

  getSeatJson = () => {
    const {
      getSeatJson /* promise resolves to v2 availability response */,
      /*
       * var basket = {
       *  "id": ...unique id for the basket...,
       *  "seats": [selectedSeats...]
       * }
       */
      variants = {},
      acceptedProducts,
      setItinerary,
      setSeatCategories,
      setOfferedProducts,
      setCurrentPassenger,
      setSegmentColumns,
      setPassengers,
      setPriceAdjustment,
      setPriceTextPrepend,
      createBasketId,
      isMobile,
      setVariants,
      setActiveLanguage,
      setBasket,
      existingBasket,
      theme,
      priceAdjustment = {
        factor: 1.0,
        name: '',
      },
      basketUpdated,
      priceTextPrepend = '',
      setUniqueCharacteristics,
      setIsEnhanced,
      addUpsellOptions,
      setSegmentsCurrentCompartment,
      setSegmentsInitialCompartment,
      addRestrictions,
      setLocale,
    } = this.props;
    const transpiledVariants = calculateConvenienceFeeVariants(variants);
    const booleanizedVariants = booleanizeVariants(transpiledVariants);
    setVariants(booleanizedVariants);

    window.onSeatingChoiceBasketChange = this.props.onSeatingChoiceBasketChange;

    this.promiseTimeout(getSeatJson, SEATMAP_TIMEOUT).then(
      (data) => {
        if (!data) {
          this.seatmapSearchFailed();
          return;
        }
        let uniqueCharacteristics = [];
        let isEnhanced = false;
        if (data.results?.flight) {
          // Check if data format needs to be converted
          uniqueCharacteristics = this.getUniqueCharacteristics(data);
          isEnhanced = this.isEnhanced(uniqueCharacteristics);
          data = convertFromRawToV2_2Format(data);
        }
        if (
          data.status === 'failed' ||
          data.product_status?.seat?.status === 'failed'
        ) {
          this.seatmapSearchFailed();
          return;
        }
        const { search_id, language = 'en-US', passengers = [] } = data;
        const { products = {}, itineraries = [], seatmaps = [] } = data.results;
        const { journeys = [] } = itineraries[0];
        const segmentColumns = {};
        const seatmapsBySegment = {};
        seatmaps.forEach((seatmap) => {
          seatmapsBySegment[seatmap.segment_id] = seatmap;
        });
        // append inbound and outbound to flights
        journeys.forEach((journey, index) => {
          if (journey?.segments) {
            journey.segments.forEach((segment) => {
              segment.direction = index === 0 ? 'outbound' : 'inbound';
              const seatmap = seatmapsBySegment[segment.segment_id];
              segmentColumns[segment.segment_id] =
                seatmap?.decks?.[0]?.compartments?.[0]?.definition?.columns;
            });
          }
        });
        setSegmentColumns(segmentColumns);
        const legs = journeys.reduce((previousValue, currentValue) => {
          const { segments = [] } = currentValue;
          return [...previousValue, ...segments];
        }, []);

        const getCabinType = (seatmap) =>
          seatmap?.decks?.[0]?.compartments?.[0]?.definition?.cabin.toLowerCase();
        const hasAllegrisCategories = (seatmap) => {
          const intersection = (array1, array2) =>
            array1.filter((value) => array2.includes(value));
          const normalizedSeatCategories = seatmap.seat_categories.map((c) =>
            c.display_name.replaceAll(' ', '').toLowerCase()
          );
          const cabinType = getCabinType(seatmap);
          switch (cabinType) {
            case 'business':
              return (
                intersection(normalizedSeatCategories, [
                  'businessextraspaceseat',
                  'businessextralongbed',
                  'businesssuite',
                  'businessprivacyseat',
                  'businessclassicseat',
                ]).length >= 3
              );
            case 'first':
              return (
                intersection(normalizedSeatCategories, [
                  'firstclasssinglesuite',
                  'firstclasssuiteplus',
                ]).length >= 1
              );
            default:
              return false;
          }
        };

        // We check two variant names due to a quirk in V1 with how variants are set. If the variant is part of an AB test, it'll have 'test'
        // prepended to it. If it's set by the partner, it will not have 'test' prepended. We need to account for both cases for the seatmap redesign
        // as partners have the option to enable the redesign.
        const use_seatmap_redesign =
          booleanizedVariants['use_seatmap_redesign'] ||
          booleanizedVariants['test_use_seatmap_redesign'];
        legs.forEach((leg) => {
          const seatmap = seatmapsBySegment[leg.segment_id];
          if (seatmap) {
            const cabinType = getCabinType(seatmap);
            seatmap['useEnhancedDetails'] =
              use_seatmap_redesign &&
              ['LH', 'LX', 'OS', 'SN', 'EW'].includes(
                seatmap?.carrier ??
                  leg.operating_airline ??
                  leg.marketing_airline
              );
            seatmap['useEnhancedSeatmap'] =
              seatmap['useEnhancedDetails'] &&
              ['business', 'first'].includes(cabinType);
          }
        });

        /*
           Data for carriers from the LGH group may contain different elements in columns E and F for the same row,
           although in reality these are actually the same column. We want to display these columns as a single one
           while keeping the original data format for API consumers, therefore we adjust it here.
        */
        const getElementPriority = (elementDescription) => {
          const priority_map = {
            seat: 10,
            bathroom: 9,
            stairs: 8,
            kitchen: 7,
            bassinet: 6,
            luggage_storage: 5,
            closet: 4,
            airphone: 3,
            crew_seat: 2,
            table: 1,
            missing: 0,
          };
          return priority_map[elementDescription] ?? 1;
        };

        seatmaps
          .filter(
            (seatmap) =>
              seatmap.useEnhancedSeatmap && hasAllegrisCategories(seatmap)
          )
          .forEach((seatmap) => {
            const compartments = seatmap.decks.flatMap(
              (deck) => deck.compartments
            );

            const middleRowGroups = compartments
              .flatMap((compartment) => compartment.seat_rows)
              .flatMap((seatRow) => seatRow.row_groups)
              .filter((rowGroup) =>
                rowGroup.some((element) =>
                  element.columns.some((column) => ['E', 'F'].includes(column))
                )
              );

            // Additional condition to collapse columns
            const shouldMergeColumns = middleRowGroups.every((rowGroup) => {
              const middleColumnElements = rowGroup.filter((element) =>
                element.columns.some((column) => ['E', 'F'].includes(column))
              );
              const [element1, element2] = middleColumnElements;
              return (
                (element1.type !== 'seat' && element2.type !== 'seat') ||
                (element1.type === 'seat' && element2.type === 'missing') ||
                (element1.type === 'missing' && element2.type === 'seat')
              );
            });

            if (!shouldMergeColumns) {
              return;
            }

            // Remove F column header
            compartments.forEach((compartment) => {
              compartment.definition?.columns?.forEach((columnGroup) => {
                const columnToRemoveIndex = columnGroup.findIndex(
                  (column) => column === 'F'
                );
                if (columnToRemoveIndex !== -1) {
                  columnGroup.splice(columnToRemoveIndex, 1);
                }
              });
            });

            // Remove elements in E-F columns based on priority order
            for (const rowGroup of middleRowGroups) {
              const middleColumnElements = rowGroup.filter((element) =>
                element.columns.some((column) => ['E', 'F'].includes(column))
              );

              if (middleColumnElements.length === 2) {
                const [element1, element2] = middleColumnElements;
                const elementToRemove =
                  getElementPriority(element1.type) <
                  getElementPriority(element2.type)
                    ? element1
                    : element2;
                rowGroup.splice(rowGroup.indexOf(elementToRemove), 1);
              }
            }
          });

        for (const leg of legs) {
          const id = leg.segment_id;
          const seatmap = seatmapsBySegment[id];
          const upsellOptions = seatmap.upsell_options ?? [];
          const restrictions = seatmap.restrictions ?? [];
          addUpsellOptions({ id, upsellOptions });
          addRestrictions({ id, restrictions });
          const compartment = getSelectedCompartment(upsellOptions);
          setSegmentsCurrentCompartment({
            id,
            compartment,
          });
          setSegmentsInitialCompartment({
            id,
            compartment,
          });
        }

        setDefaultPassengers(passengers, languageConfigurations, language);
        if (passengers.length >= 1) setCurrentPassenger(passengers[0]);
        setActiveLanguage(language);
        setLocale(language);
        isMobile(this.state.isMobile);
        setOfferedProducts(products);
        setSeatCategories({ legs, colors: theme.seats, seatmapsBySegment });
        setItinerary({
          legs,
          segmentIndex: this.props.segmentIndex,
          seatmapsBySegment,
        });
        createBasketId(search_id);
        setPassengers(passengers);
        setPriceAdjustment(priceAdjustment);
        setPriceTextPrepend(priceTextPrepend);
        setUniqueCharacteristics(uniqueCharacteristics);
        setIsEnhanced(isEnhanced);
        /// This is for passing in an existing basket from the SDK
        if (!basketUpdated) {
          if (existingBasket) {
            const acceptedProductsFromBasket = this.basketToAcceptedProducts(
              existingBasket,
              data
            );
            setBasket({
              basketData: this.getBasketData(
                seatmapsBySegment,
                passengers,
                products,
                acceptedProductsFromBasket
              ),
            });
          } else {
            /// This is used for pre-filling the basket from past sessions for Hopper.
            setBasket({
              basketData: this.getBasketData(
                seatmapsBySegment,
                passengers,
                products,
                acceptedProducts
              ),
            });
          }
        }
        if (window.gordianInternal && window.gordianInternal.onSeatmapLoaded) {
          window.gordianInternal.onSeatmapLoaded();
        }
      },
      () => {
        this.seatmapSearchFailed();
      }
    );
  };

  componentDidUpdate(prevPros) {
    if (prevPros.getSeatJson !== this.props.getSeatJson) {
      const { initialize, addTranslationForLanguage } = this.props;
      initializeLocalization(initialize, addTranslationForLanguage);
      this.getSeatJson();
    }
    if (prevPros.relativeWidthRatio !== this.props.relativeWidthRatio) {
      this.setState({
        width: typeof window !== 'undefined' ? window.innerWidth : 1200,
        isMobile:
          this.state.width <=
          MOBILE_WIDTH_THRESHOLD / this.props.relativeWidthRatio,
      });
    }
    if (prevPros.categories !== this.props.categories) {
      this.props.setShouldHidePrice(
        shouldHideSeatPrice(this.props.categories, this.state.isMobile)
      );
      this.props.setShouldAbbreviateSeatPrice(
        shouldAbbreviateSeatPrice(this.props.categories, this.state.isMobile)
      );
    }
  }

  componentDidMount() {
    const { initialize, addTranslationForLanguage } = this.props;
    initializeLocalization(initialize, addTranslationForLanguage);
    this.getSeatJson();
    this.updateRelativeWidthRatio();
    window.addEventListener('resize', this.updateRelativeWidthRatio);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateRelativeWidthRatio);
  }

  updateRelativeWidthRatio = () => {
    // Determine relativeWidthRatio for responsive UI for smaller embedded seatmap
    if (!this.props.modal) {
      const mapWidth = document
        .getElementById('gordian-seatmap-dialog')
        .getBoundingClientRect().width;
      const windowWidth = document.documentElement.clientWidth;
      const seatmapToScreenRatio = mapWidth / windowWidth;
      if (
        seatmapToScreenRatio < 0.98 &&
        seatmapToScreenRatio !== this.props.relativeWidthRatio
      ) {
        this.props.setRelativeWidthRatio(seatmapToScreenRatio);
      }
    } else if (this.props.relativeWidthRatio !== 1) {
      this.props.setRelativeWidthRatio(1);
    }
  };

  render() {
    const { modal, booleanizedVariants = {} } = this.props;

    const { test_seatmap_no_close = false } = booleanizedVariants;
    const modalStyle = 'gordian-modal';

    let brand = undefined;
    if (booleanizedVariants.test_uber_theme) brand = 'uber';

    let bgColor = '';

    switch (brand) {
      case 'uber':
        bgColor = 'bg-white';
        break;
      default:
        bgColor = 'gr-bg-gray-200';
        break;
    }

    return (
      <SeatmapContainer
        modal={modal}
        modalStyle={modalStyle}
        fontFamily={this.props.fontFamily}
      >
        <ThemedLoader modal={this.props.modal} />
        <div
          className={`gordian-modal-guts gordian-embedded-guts gr-block ${bgColor}`}
        >
          {modal && !(test_seatmap_no_close === true) ? (
            // The zIndex on the close button controls whether the ThemedLoader hides the button. We hide it for Hopper, but not for everyone else
            <div
              id="close-button-container"
              className="gr-flex gr-bg-white gr-h-10 gr-flex-row-reverse gr-justify-between gr-w-full"
            >
              <CloseButton
                disabled={this.props.basketChanging}
                isOptIn={this.props.isOptIn}
              />
            </div>
          ) : null}

          {this.state.showSeatMap ? (
            <SeatMapStepSelector
              modal={this.props.modal}
              isOptIn={this.props.isOptIn}
            />
          ) : (
            <SeatingChoice />
          )}
        </div>
      </SeatmapContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedSeat: state.selectedSeat,
  showingCategories: state.session.showingCategories,
  categories: state.categories,
  isMobile: state.session.mobile,
  theme: state.session.theme,
  exitModal: state.session.exitModal,
  acceptedExitRegulation: state.session.acceptedExitRegulation,
  scrolled: state.seatDetails.bottom,
  acceptedProducts: state.session.acceptedProducts,
  booleanizedVariants: state.session.variants,
  basketChanging: state.session.basketChanging,
  loading: state.session.loading,
  basketUpdated: state.session.basketUpdated,
  uniqueCharacteristics: state.session.uniqueCharacteristics,
  relativeWidthRatio: state.session.relativeWidthRatio,
  restrictionMap: state.itinerary.restrictionMap,
});

const ModalLocalize = withLocalize(Modal);

export default connect(mapStateToProps, {
  setItinerary,
  setFlights,
  setSeatCategories,
  setOfferedProducts,
  setCurrentPassenger,
  setPassengers,
  createBasketId,
  setLoading,
  isMobile,
  showModal,
  setVariants,
  setShouldHidePrice,
  setBasket,
  setShouldAbbreviateSeatPrice,
  showCategories,
  setSegmentColumns,
  setPriceAdjustment,
  setPriceTextPrepend,
  setUniqueCharacteristics,
  setIsEnhanced,
  addUpsellOptions,
  setSegmentsCurrentCompartment,
  setSegmentsInitialCompartment,
  setRelativeWidthRatio,
  addRestrictions,
  hasSeats,
  setLocale,
})(ModalLocalize);
